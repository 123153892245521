import request from '@/utils/request'

// 产品列表
export function productInfoGet(params) {
    return request({
        url: '/qy/product-info/query',
        method: 'get',
        params,
    })
}

// 添加订单
export function addOrder(data) {
    return request({
        url: '/qy/order/addOrder',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

// 订单信息
export function productInfoQuery(params) {
    return request({
        url: '/qy/order/query',
        method: 'get',
        params,
    })
}



// 订单信息
export function productInfoItems(params) {
    return request({
        url: '/qy/order/items',
        method: 'get',
        params,
    })
}

// 支付结果回调
export function payResult(params) {
    return request({
        url: '/qy/Pay/queryPayResult',
        method: 'get',
        params,
    })
}


/**
 * 
 * 英文的订单接口
 * 
 */

// 国外邮箱发送
export function sendEmailConfirm(data) {
    return request({
        url: '/qy/order/sendEmailConfirm',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 邮箱确认发送认证
export function confirmEmailSuccess(data) {
    return request({
        url: '/qy/order/confirmEmailSuccess',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 获取邮箱认证状态
export function getEmailConfirm(data) {
    return request({
        url: '/qy/order/getEmailConfirm',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

// 提交订单
export function comfirmOrder(params){
    return request({
        url: '/qy/order/payOrder',
        method: 'get',
        params
    })
}

// 跳转地址查询
export function getCounterUrl(params){
    return request({
        url: '/qy/Pay/queryPayByOrderId',
        method: 'get',
        params
    })
}

// 查询国外支付结果
export function getAuthorizeOrder(params){
    return request({
        url: '/qy/order/authorizeOrder',
        method: 'get',
        params
    })
}
